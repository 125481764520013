import { type LoaderFunction } from '@remix-run/node'
import type { OffsetPagination, Post as PostType } from '~/gql/queries.ts'
import Post from '~/components/post.tsx'
import { getPosts } from '~/utils/graphql.ts'
import { Link, useLoaderData, useSearchParams } from '@remix-run/react'

type LoaderData = {
	posts?: PostType[]
	postsPerPage: number
	pageInfo?: OffsetPagination
}
export let loader: LoaderFunction = async ({
	request,
}): Promise<LoaderData> => {
	const { searchParams } = new URL(request.url)

	const offset = Number(searchParams.get('offset') || 0)

	const { pageInfo, posts, postsPerPage } = await getPosts(offset)

	return { postsPerPage, posts, pageInfo }
}

export default function IndexRoute() {
	const [searchParams] = useSearchParams()
	const offset = Number(searchParams.get('offset'))
	const { postsPerPage, posts, pageInfo } = useLoaderData<LoaderData>()

	const offsetParam = (inc: boolean = true) => {
		const newOffset = offset + (inc ? 1 : -1) * postsPerPage
		return newOffset === 0 ? '.' : `?offset=${newOffset}`
	}

	return (
		<>
			{posts?.map(post => (
				<Post key={post.id} post={post} isExcerpted={true} />
			))}
			<div className="flex flex-row justify-center space-x-4">
				<div>
					{pageInfo?.hasPrevious ? (
						<Link
							to={offsetParam(false)}
							className="text-theme-yellow hover:text-theme-brown hover:underline"
						>
							&lsaquo;&lsaquo; Prev
						</Link>
					) : (
						<span className="text-theme-brown-muted opacity-60">« Prev</span>
					)}
				</div>
				<div className="opacity-60">||</div>
				<div>
					{pageInfo?.hasMore ? (
						<Link
							to={offsetParam(true)}
							className="text-theme-yellow hover:text-theme-brown hover:underline"
						>
							Next »
						</Link>
					) : (
						<span className="text-theme-brown-muted opacity-60">Next »</span>
					)}
				</div>
			</div>
		</>
	)
}
